import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AppIcon from '../Assets/nealia_prevent_icon negatiu.png';
//import AppIcon from '../Assets/PRL_logo_1024-x1024_negativo.png';
import { authContext } from '../adalConfig';
import { IMe } from '../types';
import './Layout.css';
import Empresa from './Empresa';
import LateralMenu from './LateralMenu';
import BusinessIcon from '../Assets/ico_empresa_H.svg';
import UsersIcon from '../Assets/ico_users.svg';
import FindIcon from '../Assets/ico_find.svg';
import AppsIcon from '../Assets/ico_aplicaciones_H.svg';
import LogoutIcon from '../Assets/ico_cerrar_H.svg';
import NewsIcon from '../Assets/ico_novedad_H.svg';
import HelpIcon from '../Assets/ico_help_H.svg';
import LoaderBeauty from './Material/activityProgress';
import { OpenExternalFile } from '../helpers';
import CreateModal from './CreateDialog';
import PersonalSearcher from './PersonalSearcher';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position:'fixed',
      top:0,
      width:'100%',
      zIndex:100
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

interface IPropsLayout{
    history:any;
    match:any;
    currentPage:number;
    allowDrawer: boolean;
    version: string;
    me?:IMe;
    showEmpresa: boolean;
    showLateralMenu: boolean;
    loading?:boolean;
}
export default function MenuAppBar(props:IPropsLayout) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [lateralMenu, setLateralMenu] = React.useState(false);
  const [creationModalOpen, setModal] = React.useState(false);
  const [titleModal, setModalTitle] = React.useState("MENSAJE");
  const [modalText, setModalText] = React.useState<null | string | any>(null);
  const [modalType, setModalType] = React.useState(0);
  const [modalAction, setModalAction] = React.useState(0);
  const [loadingModal, setModalLoading] = React.useState(false);
  const [modalClassName, setModalClassName] = React.useState<undefined | string>(undefined);
  
  const open = Boolean(anchorEl);

  const drawerOpenned = Boolean(lateralMenu);

  const handleToogle = (event: React.MouseEvent<HTMLElement>) => {
    setLateralMenu(!drawerOpenned);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateModal = (typeAction: number) => {
    switch(typeAction){
        case 1: //
            break;
    }
    //this.onCloseCreationModal();
  }

  const onCloseCreationModal = () => {
    setModal(false);
  }

  const handleMenuOption=(option: number)=>{
    switch(option){
      case 1: // Perfil usuario
        break;
      case 2: // Cambio de empresa
        props.history.push(`/`);
        break;      
      case 3: // Cambio de empresa
        props.history.push(`/news`);
        break;
      case 4: // Open manual
        window.location.href = "https://vallcompanys.es/nealiaprevent/";
        break;
      case 5:
        props.history.push(`/userlist`);
        break;
      case 6:
        setModalTitle("Buscador de personal");
        setModalClassName(`dialog-data`);
        setModalType(0);
        setModalText(
          <PersonalSearcher
            handleCloseItemForm={onCloseCreationModal}
          />
        );
        setModal(true);
        break;
    }
  }

  const appLogOut = () => {
    authContext.logOut();
  }

  const handleNealiaHub = () => {
    window.location.href = "https://hub.nealia.tech/private_aplicaciones";
  }

  return (
    <div className={classes.root}>
      <AppBar style={{backgroundColor:'#00D3FF', height:75}} position="static">
        <Toolbar>
          <table cellSpacing={0} cellPadding={0} style={{width:'100%'}}>
            <tbody>
                <tr>
                  <td style={{width:'55%'}}>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'start', width:'100%'}}>
                      <img style={{ cursor:'pointer' }} onClick={()=>props.history.push(`/`)} height={50} width={'auto'} src={AppIcon}></img>
                    </div>
                  </td>
                  <td style={{width:'45%', textAlign:'right', justifyContent:'right', maxWidth:'200px'}}>
                    <span>{props.me?.userIdName}</span>
                    <IconButton
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                  </td>
                </tr>
                
            </tbody>
          </table>

          <div>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
              style={{ marginTop:40}}
            >
              {/*<MenuItem disabled onClick={()=>handleMenuOption(1)}>{`Perfil`}</MenuItem>*/}
              <div onClick={()=>handleMenuOption(2)} style={{display:'flex',marginLeft:10, marginTop:10, borderTop:'1px solid #00DEFF'}}>
                  <img style={{margin:'auto', cursor:'pointer'}} width={'20px'} src={BusinessIcon} />
                  <MenuItem style={{ width:'180px' }}/*style={{borderTop:'2px solid #ddd'}}*/>{`Cambiar de centro`}</MenuItem>
              </div>
              {[50,100].indexOf(props.me?.role) > -1 ?
                <div onClick={()=>handleMenuOption(5)} style={{display:'flex',marginLeft:10, marginTop:10, borderTop:'1px solid #00DEFF'}}>
                  <img style={{margin:'auto', cursor:'pointer'}} width={'20px'} src={UsersIcon} />
                  <MenuItem style={{ width:'180px' }}/*style={{borderTop:'2px solid #ddd'}}*/>{`Listado de usuarios`}</MenuItem>
                </div>
              :null
              }
              {props.me && [100,50,20,11].indexOf(props.me?.role) > -1 && props.me.empresas.length > 1 ?
                <div onClick={()=>handleMenuOption(6)} style={{display:'flex',marginLeft:10, marginTop:10, borderTop:'1px solid #00DEFF'}}>
                  <img style={{margin:'auto', cursor:'pointer'}} width={'20px'} src={FindIcon} />
                  <MenuItem style={{ width:'180px' }}/*style={{borderTop:'2px solid #ddd'}}*/>{`Buscador personal`}</MenuItem>
                </div>
              :null
              }
              <div onClick={()=>handleMenuOption(3)} style={{display:'flex',marginLeft:10, marginTop:5, borderTop:'1px solid #00DEFF'}}>
                  <img style={{margin:'auto', cursor:'pointer'}} width={'20px'} src={NewsIcon} />
                  <MenuItem style={{ width:'180px' }}>{`Novedades`}</MenuItem>
              </div>
              <div onClick={()=>handleMenuOption(4)} style={{display:'flex',marginLeft:10, marginTop:5, borderTop:'1px solid #00DEFF'}}>
                  <img style={{margin:'auto', cursor:'pointer'}} width={'25px'} src={HelpIcon} />
                  <MenuItem style={{ width:'180px' }}>{`Manual App`}</MenuItem>
              </div>
              <div onClick={()=>handleNealiaHub()} style={{display:'flex',marginLeft:10, marginTop:5, borderTop:'1px solid #00DEFF'}}>
                  <img style={{margin:'auto', cursor:'pointer'}} width={'20px'} src={AppsIcon} />
                  <MenuItem style={{ width:'180px' }}/*style={{borderTop:'2px solid #ddd'}}*/>{`Mis aplicaciones`}</MenuItem>
              </div>
              <div onClick={()=>appLogOut()} style={{display:'flex',marginLeft:10, marginTop:5, borderTop:'1px solid #00DEFF'}}>
                  <img style={{margin:'auto', cursor:'pointer'}} width={'20px'} src={LogoutIcon} />
                  <MenuItem style={{ width:'180px' }} /*style={{borderTop:'2px solid #ddd'}}*/>{`Cerrar sesión`}</MenuItem>
              </div>
              <MenuItem style={{borderTop:'1px solid #00DEFF'}} disabled ><strong style={{fontSize:'12px'}}>{`${props.version}`}</strong></MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      {props.showEmpresa?
        <Empresa
            match={props.match}
            me={props.me}
            history={props.history} 
            handleToogle={handleToogle}
        />
      :
        null
      }

      {props.showLateralMenu?
        <LateralMenu
            match={props.match}
            me={props.me}
            history={props.history}
            opened={drawerOpenned}
            handleToogle={handleToogle}
        />
      :
        null
      }

      {props.loading?
        <LoaderBeauty visible={true}/>
      :
        null
      }

      {creationModalOpen?
          <CreateModal
              onCloseCreationModal={onCloseCreationModal}
              isOpen={creationModalOpen}
              inputText={modalText}
              typeModal={modalType}
              action={handleCreateModal}
              typeAction={modalAction}
              loadingModal={loadingModal}
              titleModal={titleModal}
              className={modalClassName}
          />
      :
          null
      }

    </div>
  );
}