import React, { Component } from 'react';
import Layout from './Layout';
import PaginatedTable from './Material/PaginatedTableListadoUsuarios';
import {filter, includes, cloneDeep, groupBy} from 'lodash';
import { SelectorField, CheckBoxField, TextFieldInput } from './FormsWidgets';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import SearchField from './SearchField';
import FiltersResponsive from './Filters/Filters_responsive';
import { IMe, IIIRegister, IFilter } from '../types';
import OrderService from '../ApiClients/OrderService';
import IconButton from '@material-ui/core/IconButton';
import Loader from './Loader';
import LoaderBeauty from './Material/activityProgress';
import ReactExport from 'react-export-excel';
import {GetDatetimeFormat} from '../vc_general';
import List from './Material/MultiSelect';

interface IListadoUsuarioProps{
    history:any;
    match:any;
    me?: IMe;
    version: string;
    filters: IFilter;
    handleChangeFilter: Function;
    handleCheckFilter: Function;
}

interface IListadoUsuarioState {
    rowSelected: number;
    creationModalOpen:boolean;
    creationItemOpen: boolean;
    creationModalOpenEmail:boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    modalClassName:string;
    htmlBody:boolean;
    sendingEmail:boolean;
    emailResult:number;
    currentYearSelected: number|string;
    loading:boolean;
    loadingOptions:boolean;
    loadingDocuments:boolean;
    documents:any;
    empresas:any;
    //filters:any;
    options?:any;
    searchValue:string;
    creatingItem:boolean;
    loadingModal:boolean;
    titleModal:string;
    downloaddata:boolean;

    tipo: Number;
    filter_id: string;
}

const fields = [
    {
        id: 'empresas',
        name: 'Empresas',
        required: false,
        style:1,
        type: 1
    },
    {
        id: 'active',
        name: 'Estado',
        required: false,
        style:1,
        type: 2
    },
    // {
    //     id: 'empresas',
    //     name: 'Empresas',
    //     required: false,
    //     style:1,
    //     type: 3
    // },
];

const exportDataExcel=(data:Array<IIIRegister>)=>{
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    data = data.map((item:IIIRegister, idx:number)=>{
        return({
            ...item,
            dtCreated: GetDatetimeFormat(item.dtCreated),
            active: (item.active ? 'Activo' : 'No Activo'),            
        })
    })
    const grouped:any = groupBy(data, 'tipo');
    const d = new Date();
    const date = `${d.getFullYear()}${String(d.getMonth()+1).padStart(2,'0')}${String(d.getDate()).padStart(2,'0')}`;
    
    let sheets = Object.keys(grouped).map((key:string,index:number)=>{        
        return(
            <ExcelSheet data={grouped[key]} name={'Usuarios'} key={index}>
                <ExcelColumn label="ID" value="id"/>
                <ExcelColumn label="Nombre" value="name"/>
                <ExcelColumn label="Apellido" value="surname"/>
                <ExcelColumn label="Fecha Creación" value="dtCreated"/>
                <ExcelColumn label="Estado" value="active"/>                
            </ExcelSheet>
        );
    });

    return (
        <ExcelFile filename={`ListadoDeUsuarios_${date}`} hideElement={true} element={<button>{`Descargar`}</button>}>
            {sheets}
        </ExcelFile>
    );
}

class ListadoUsuario extends Component<IListadoUsuarioProps, IListadoUsuarioState> {
    constructor(props: IListadoUsuarioProps) {
        super(props);
        this.state = {
            rowSelected: -1,
            //dataIncome: dataIncome,
            documents:[],
            creationModalOpen:false,
            creationItemOpen:false,
            creationModalOpenEmail:false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            sendingEmail:false,
            emailResult:-1,
            htmlBody:false,
            currentYearSelected: new Date().getFullYear(),
            loading: true,
            empresas: [],
            loadingOptions: true,
            loadingDocuments: true,
            //filters:{},
            searchValue: '',
            creatingItem:false,
            loadingModal: false,
            titleModal: `MENSAJE`,
            downloaddata:false,
            modalClassName:'dialog-little',

            tipo: Number(this.props.match.params.tipo),
            filter_id: 'f_ul'
        };
    }

    componentDidMount(){
        this.reviewPage();
        this.loadData();
    }

    componentDidUpdate(prevProps:any, props:any){
        if(prevProps.me !== this.props.me)
            this.loadData();
        this.reviewPage();
    }

    loadListado = async () => {
        let response = await OrderService.loadListadoUsuarios();        
        let empresas = this.props.me?.empresas.map((emp:any, index:number)=>{
            return {active:true, id:index, name: emp.description};
        })
        this.setState({loadingDocuments: false, documents: response, empresas : empresas});
    }

    loadOptions = async () => {
        let response = [{'empresas':this.state.empresas, 'active':[{'true':true}, {'false':false}]}]
        this.setState({loadingOptions: false, options: response});
    }

    reviewPage=()=>{
        this.props.handleCheckFilter(this.props.match.params.centrotrabajoId);        
        if(this.props.me && [50,100].indexOf(this.props.me?.role) == -1)
            this.props.history.push(`/`);
    }

    loadData = () => {
        const {me} = this.props;
        if(!me)
            return;        
        this.loadListado();
        this.loadOptions();
        
    }

    handleFilterDocuments=(documents:any, f: any)=>{
        let newDocuments = cloneDeep(documents);
        
        //** FILTRA POR LOS FILTROS APLICADOS */
        const keys = Object.keys(f);
        const values:any = Object.values(f);
        const emps = this.state.empresas;
        if(keys.length > 0){
            for(let a=0; a<keys.length;a++){     
                if(["empresas"].indexOf(keys[a])  > -1)
                    newDocuments = filter(newDocuments, function(o){
                        let emp = emps[values[a]];
                        return o.business.indexOf(emp.name) > -1 
                    })
                else           
                    newDocuments = filter(newDocuments, function(o){return o[keys[a]] == values[a]})
            }
        }

        //** FILTRA POR EL VALOR DE BÚSQUEDA */
        const searchValue = this.state.searchValue.toLowerCase();

        if(newDocuments.length > 0 && searchValue && searchValue.length > 0){
            newDocuments = filter(newDocuments, function(o){return includes(o.name.toLowerCase(), searchValue) || includes(o.surname.toLowerCase(), searchValue) || includes(o.dtCreated.toLowerCase(), searchValue) });
        }                
        
        return newDocuments;
    }

    handleDownloadList = (data:Array<any>) => {
        if(data.length == 0)
            this.setState({
                modalAction: -1,
                modalText: `¡ATENCIÓN! No hay ningún registro para exportar.`,
                modalType: 1,
                creationModalOpen: true,
                modalClassName:'dialog-little'
            })
        else
            this.setState({
                downloaddata: true
            }, ()=>setTimeout(() => {
                this.setState({downloaddata: false})
            }, 2500))
    }

    handleSearchChange=(evt:any)=>{
        this.setState({searchValue:evt.target.value})
    }

    handleChangeSelectDevices=(e:any, list:any)=>{
        // let nRegister = cloneDeep(this.state.register);
        // nRegister.puestoTrabajo = list;
        
        // // Update pre set epis list
        // if(list !== null){
        //     list.epis.map((item:IIJobplace, idx:number)=>{
        //         nRegister.m01.push(item);
        //     })
        // }
        // else // Reset pre set epis list
        //     nRegister.m01 = [];
        // this.setState({ register: nRegister });
    }

    handleClearSearchValue=()=>{
        this.setState({searchValue:''})
    }

    handleItemClick=(item:any)=>{
        this.props.history.push(`${window.location.hash.replace('#','')}/${item.id}`)
    }

    RenderFilters = (optionsfilters:any, arrayfilters:any) => {
        if(optionsfilters == undefined)
            return `Fallo en la carga de los filtros, vuelva a refrescar la página.`;

        let filters = fields.map((item:any, index:number)=>{
            if(item.type == 1)
                return (
                    <SelectorField
                        key={index}
                        name={item.name}
                        id={item.id}
                        deviceData={arrayfilters}
                        fieldsData={this.state.empresas}
                        handleFieldChange={this.handleChangeFilter}
                        required={item.required}
                        style={item.style}
                        className='filter-item'
                    />
                );
            else if(item.type == 2){
                
                    return (
                        <SelectorField
                            key={index}
                            name={item.name}
                            id={item.id}
                            deviceData={arrayfilters}
                            fieldsData={[{active:true, id:true, name:'Activo'},{active:true, id:false, name:'No Activo'}]}
                            handleFieldChange={this.handleChangeFilter}
                            required={item.required}
                            style={item.style}
                            className='filter-item'
                        />
                    );   
            }
            else if(item.type == 3){
                return(
                    <div style={{maxWidth:'800px'}}>
                        <List id={"business"} data={arrayfilters[this.state.filter_id]} multiple={false} name={'Empresas'} placeholder={'Empresas'} options={this.state.empresas} handleChangeSelect={(e:any, list:any)=>this.handleChangeSelectDevices(e,list)}/>
                    </div>
                )
            }else{
                return null;
            }
        })

        return filters;
    }

    handleChangeFilter=(id:string, evt:any)=>{
        let newFilters = cloneDeep(this.props.filters[this.state.filter_id]);
        newFilters[id] = evt.target.value;

        this.props.handleChangeFilter(this.state.filter_id, newFilters, false);
    }

    handleCleanFilters = (evt:any)=>{
        evt.stopPropagation();
        this.props.handleChangeFilter(this.state.filter_id, {}, false);
    }

    render() {
        const {loadingOptions, loadingDocuments, options, documents, searchValue, filter_id} = this.state;
        const {me, filters} = this.props;
        const apply_filters: any = filters[filter_id];
        const f_documents = this.handleFilterDocuments(documents, apply_filters);
        
        return (
            <div className='App-basic' style={{ paddingTop:75 }}>
                <Layout 
                    history={this.props.history} 
                    currentPage={1}
                    allowDrawer={true}
                    match={this.props.match}
                    version={this.props.version}
                    me={me}
                    showEmpresa={false}
                    showLateralMenu={false}
                />
                <div className='App-body-webBar'>
                    {me?
                        [50,100].indexOf(me?.role) == -1?
                            <div>
                                <div className="App-header">
                                    Listado de Usuarios
                                </div>
                                <label style={{ marginLeft:10 }}>
                                    {`No tiene permisos para visualizar este espacio.`}
                                </label>
                            </div>
                        :
                            <div>
                                <div className="App-header">
                                    {`Listado de Usuarios`}
                                    <div className="App-header-options App-header-options-responsive">
                                    <SearchField placeholder="Buscar..." value={searchValue} allowSearch={true} onValueChange={this.handleSearchChange} handleClearSearchValue={this.handleClearSearchValue}/>
                                        <div style={{ display:'flex', flexWrap: 'wrap', gap:'10px' }}>                                            
                                            <div className="App-header-options-item download-list-button">
                                                <IconButton onClick={()=>this.handleDownloadList(f_documents)} aria-label="download list">
                                                    <DownloadIcon />
                                                </IconButton>
                                            </div>                                            
                                        </div>    
                                        <FiltersResponsive
                                                    loadingOptions={loadingOptions}
                                                    RenderFilters={this.RenderFilters}
                                                    apply_filters={apply_filters}
                                                    handleCleanFilters={this.handleCleanFilters}
                                                    options={options} />                                    
                                    </div>
                                </div>                                
                                
                                {documents.length > 0?
                                    <div style={{width:'auto', textAlign:'right'}}>
                                        <p style={{fontWeight:'bold'}}>
                                            {`Visualizando: ${f_documents.length}/${documents.length} ${documents.length == 1?`registro`:`registros`}`}
                                        </p>
                                    </div>
                                :
                                    null
                                }

                                <div>
                                    <PaginatedTable
                                        allowExport={true}
                                        allowSearch={false}
                                        data={f_documents}
                                        role={me?.role}
                                        handleSearch={this.handleSearchChange}
                                        searchValue={searchValue}
                                        onClearSearchValue={this.handleClearSearchValue}                                        
                                        handleItemClick={this.handleItemClick}
                                        handleDownloadList={this.handleDownloadList}
                                    />
                                </div>                                
                            </div>
                    :
                        this.state.loading?
                            <div style={{marginLeft:20}}>
                                <Loader loading={true}/>
                            </div>
                        :
                            `Fallo en la carga del perfil, volver a intentar`
                    }                    

                    {this.state.downloaddata?
                        exportDataExcel(f_documents)
                    :
                        null
                    }
                    <LoaderBeauty visible={loadingOptions||loadingDocuments?true:false}/>
                </div>
            </div>
        );
    }

}

export default ListadoUsuario;