import { fetchAPI
 } from '../helpers';
import appData from './appData'
import { IIIPersonal, IMe } from '../types';
import { filter, find } from 'lodash';
import { referencia, origen, seccion, puestos, responsable, estado, documents, me, Dashboard } from './DummyData';
import { array } from 'yup';
import { graphic } from 'echarts';
// import Dashboard from '../Components/Dashboard';

const baseURL = 'https://fnc-nealia-prevent-01.azurewebsites.net/api/';

class OrderService {

    static LoadOptions = async (centrotrabajoId:number) => {
        try {
          let url = `${baseURL}LoadOptions?centrotrabajoId=${centrotrabajoId}`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          // return {referencia:referencia, origen: origen, seccion: seccion, puestos: puestos, responsable: responsable, estado: estado};
          return response;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static LoadPanelControl = async (centrotrabajoId:number) => {
        try {
          let url = `${baseURL}LoadControlPanel?centrotrabajoId=${centrotrabajoId}`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          //return {referencia:referencia, origen: origen, seccion: seccion, puestos: puestos, responsable: responsable, estado: estado};
          return response;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static LoadUsers = async (centrotrabajoId:number) => {
        try {
          let url = `${baseURL}LoadUsers?centrotrabajoId=${centrotrabajoId}`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          return response;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }
  
      static LoadSections = async (centrotrabajoId:number) => {
        try {
          let url = `${baseURL}LoadSections?centrotrabajoId=${centrotrabajoId}`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          return response;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static LoadJobPlace = async (centrotrabajoId:number) => {
        try {
          let url = `${baseURL}LoadJobPlace?centrotrabajoId=${centrotrabajoId}`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          return response;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static LoadListado = async (centrotrabajoId:number, tipo:number) => {
        try {
          let url = `${baseURL}LoadListado?centrotrabajoId=${centrotrabajoId}&tipo=${tipo}`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          //return {referencia:referencia, origen: origen, seccion: seccion, puestos: puestos, responsable: responsable, estado: estado};
          return response;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static LoadListadoItem = async (centrotrabajoId:number, tipo:number, id:number) => {
        try {
          let url = `${baseURL}LoadListado?centrotrabajoId=${centrotrabajoId}&tipo=${tipo}&id=${id}`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          if(!response)
            return undefined;
          return find(response, function(o){return o.id == id});
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static LoadListadoItemLines = async (centrotrabajoId:number, tipo:number, listadoId:number) => {
        try {
          let url = `${baseURL}LoadListadoItemLines?centrotrabajoId=${centrotrabajoId}&tipo=${tipo}&listadoId=${listadoId}`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          if(!response)
            return [];
          return response;
        } catch (err) {
            console.log(err);
            return [];
        }
      }

      static LoadPanelPersonal = async (centrotrabajoId:number) => {
        try {
          let url = `${baseURL}LoadPersonal?centrotrabajoId=${centrotrabajoId}`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          return response;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static LoadPanelPersonalDoc = async (centrotrabajoId:number) => {
        try {
          let url = `${baseURL}LoadPersonalDoc?centrotrabajoId=${centrotrabajoId}`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          return response;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static LoadReports = async (centrotrabajoId:number, tipo:number) => {
        try {
          let url = `${baseURL}LoadReports?centrotrabajoId=${centrotrabajoId}&tipo=${tipo}`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          //return {referencia:referencia, origen: origen, seccion: seccion, puestos: puestos, responsable: responsable, estado: estado};
          return response;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static LoadReport = async (centrotrabajoId:number, tipo:number, id:number) => {
        try {
          let url = `${baseURL}LoadReport?centrotrabajoId=${centrotrabajoId}&tipo=${tipo}&id=${id}`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          //return {referencia:referencia, origen: origen, seccion: seccion, puestos: puestos, responsable: responsable, estado: estado};
          return response;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static LoadReportFields = async () => {
        try {
          let url = `${baseURL}LoadReportFields`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          //return {referencia:referencia, origen: origen, seccion: seccion, puestos: puestos, responsable: responsable, estado: estado};
          return response;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static SearchPersonal = async (data:IIIPersonal) => {
        try {
          let url = `${baseURL}SearchPersonal?id=${data.id}&name=${data.name}&surname=${data.surname}`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          // return {referencia:referencia, origen: origen, seccion: seccion, puestos: puestos, responsable: responsable, estado: estado};
          return response;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static saveData = async (data:any, action:number) => {
        try {
          let url = `${baseURL}saveData?action=${action}`
          let response = await fetchAPI(url, 'POST', true, {...data}, false);
          return response;
        } catch (err) {
            console.log(err);
            return [];
        }
      }
  
      static createImgBlob = async (data:any, action:number) => {
        try {
          let url = `${baseURL}createImgBlob?action=${action}`
          let response = await fetchAPI(url, 'POST', true, {...data}, false);
          return response;
        } catch (err) {
            console.log(err);
            return [];
        }
      }

      static me = async () => {
        try {
          let url = `${baseURL}me`
          let response = await fetchAPI(url, 'GET', true, undefined, false);
          //console.log(response)
          return response;
        } catch (err) {
            return undefined;
        }
      }
  
      static metricService = async (metricType:number) => { // Llamadas de registro de métricas //
        try {
            let url = `${baseURL}metrics?metricType=${metricType}`
            await fetchAPI(url, 'PUT', true, undefined, false);
        } catch(err) {
  
        }
      }

      static dashboardData = async (centrotrabajoId:number, iniDate : Date, endDate: Date) => {
        try {                    
          
          let kpis = await fetchAPI(`${baseURL}LoadCharts?type=1&centrotrabajoId=${centrotrabajoId}&iniDate=${iniDate.toISOString().slice(0, 10)}&endDate=${endDate.toISOString().slice(0, 10)}`, 'GET', true, undefined, false);
          let graficosEstado = await fetchAPI(`${baseURL}LoadCharts?type=2&centrotrabajoId=${centrotrabajoId}&iniDate=${iniDate.toISOString().slice(0, 10)}&endDate=${endDate.toISOString().slice(0, 10)}`, 'GET', true, undefined, false);
          let graficosOrigen = await fetchAPI(`${baseURL}LoadCharts?type=3&centrotrabajoId=${centrotrabajoId}&iniDate=${iniDate.toISOString().slice(0, 10)}&endDate=${endDate.toISOString().slice(0, 10)}`, 'GET', true, undefined, false);                                                  
          let graficosEPIs = await fetchAPI(`${baseURL}LoadCharts?type=4&centrotrabajoId=${centrotrabajoId}&iniDate=${iniDate.toISOString().slice(0, 10)}&endDate=${endDate.toISOString().slice(0, 10)}`, 'GET', true, undefined, false);                                                  

          let response : any[]= [];
          kpis.forEach((kpi:any, index:number) => {   
            if([3].includes(kpi.type)){
              kpi = {...kpi, graphics:[graficosEPIs[0]]}            
              response.push(kpi);
            }
            else if([1,2].includes(kpi.type)) {
              kpi = {...kpi, graphics:[graficosEstado[index], graficosOrigen[index]]}            
              response.push(kpi);
            }
          });          
          console.log(response)
          return response;
        } catch (err) {
            console.log(err);
            return undefined;
        }
      }

      static loadListadoUsuarios = async() => {
        try {
          let url = `${baseURL}LoadInvoiceData`;
          let response = await fetchAPI(url, 'GET', true, undefined, false);          
          return response;
        } catch (err) {
          console.log(err);
          return undefined;
        }
      }
}
export default OrderService;