import React, { Component } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Text, View, StyleSheet, Image, PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { Document, Page } from '../Material/pdfRenderComponents';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddBoxOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ViewIcon from '@material-ui/icons/VisibilityOutlined';
import SignIcon from '@material-ui/icons/EditOutlined';
import DatePicker from '../DatePicker';
import { IMe, IPersonal, XForm, ICentroTrabajo, VIIIQuestion, IFirmante } from '../../types';
import { SelectorField, CheckBoxField, TextFieldInput } from '../FormsWidgets';
import {GetRole, GetEmpresa} from '../../helpers';
import TakeIcon from '@material-ui/icons/CheckCircleOutline';
import {cloneDeep, filter, find, findIndex} from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddActionIcon from '@material-ui/icons/AddBox';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PageViewIcon from '@material-ui/icons/Pageview';
import { GetConcatedDate, GetDatetimeFormat } from '../../vc_general';
import OrderService from '../../ApiClients/OrderService';
import LoaderBeauty from '../Material/activityProgress';
import CreateModal from '../CreateDialog';
import List from '../Material/MultiSelect';
import Loader from '../Loader';
import { ListChildComponentProps } from 'react-window';
import {epis} from '../../ApiClients/DummyData';
import s1 from '../../Assets/widgets/s1.png';
import s2 from '../../Assets/widgets/s2.png';
import s3 from '../../Assets/widgets/s3.png';
import s4 from '../../Assets/widgets/s4.png';
import s5 from '../../Assets/widgets/s5.png';
import s6 from '../../Assets/widgets/s6.png';
import s7 from '../../Assets/widgets/s7.png';
import s8 from '../../Assets/widgets/s8.png';
import Signature from '../Material/signaturePanel';
import { Delete } from '@material-ui/icons';
import { PdfStyles } from "./pdfStyles";
import AppNealia_prevent_icon from '../../Assets/nealia_prevent_icon.png';
import AppNealia_prevent from '../../Assets/nealia_prevent.png';

const m01styles = ({
    button: {
        margin: 1,
        marginRight:15
    },
      leftIcon: {
        marginRight: 10,
    },
      rightIcon: {
        marginLeft: 1,
    },
      iconSmall: {
        fontSize: 20,
    },
    formControl: {
        margin: '1px',
        minWidth: 120,
        marginRight: 'auto',
    },
});

const styles = (theme: Theme) => createStyles({
    container: {
        padding: '10px',
        textAlign: 'center',
        overflowY:'auto'
    },
    image: {
        width: '100%',
        height: 'auto'
    },
    promotion: {
        //backgroundColor: '#f00',
        padding: '3px 15px',
        //color: '#fff',
        verticalAlign: 'middle',
    },
    root: {
    width: '100%',
    },
    paper: {
        width: '100%',
        marginTop: '5px',
        overflowX: 'auto',
        borderWidth: 2, 
        borderColor: '#00809b',
        borderStyle: 'solid',
    },
    table:{
        //tableLayout:'fixed'
        width: '100%',
    },
    row:{
        //wordWrap:'break-word'
        width:'100%',
        //backgroundColor:'#ddd'
    },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
    },
    item: {
        maxWidth:'200px',
        border: '2px solid #ccc',
        borderRadius: '15px',
        margin: '5px 10px',
        width: 'calc(100% - 20px)!important',
        boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.50)',
        cursor: 'pointer',
        paddingTop: '10px',
        paddingBottom: '10px',
        position: 'relative',
    },
});

const useStyles = ({
    page: {
        //display: 'row',
        //backgroundColor: '#E4E4E4',
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    body: {
        paddingTop: 15,
        paddingBottom: 40,
        paddingLeft: 15,
    },
    logo: {
        width: 60,
        height: 60,
    },
    logoFito: {
        width: 'auto',
        height: 'auto',
    },
    bussinessData: {
        width: 'auto',
        height: 'auto',
    },
    header:{
        marginTop:7,
        fontSize:10
    },
    secondHeader:{
        marginTop:7,
        fontSize:11,
        marginBottom:10
    },
    headerTable:{
        fontSize:10,
        marginVertical:'auto',
        padding:3,
        borderTop:'1pt solid rgba(0,0,0,0.31)',
        borderBottom:'1pt solid rgba(0,0,0,0.31)',
        borderLeft:'1pt solid rgba(0,0,0,0.31)',
    },
    lineTable:{
        fontSize:9,
        marginVertical:'auto',
        padding:3,
    },
    content: {
        marginRight:15,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    },
    content02: {
        marginRight:0,
        '@media min-width: 400': {
            flexDirection: 'row',
        },
    }
});

function GetIconM01 (icon:number) {
    switch(icon){
        case 1:
            return s1;
        case 2:
            return s2;
        case 3:
            return s3;
        case 4:
            return s4;
        case 5:
            return s5;
        case 6:
            return s6;
        case 7:
            return s7;
        case 8:
            return s8;
        default:
            return undefined;
    }
}

const PdfForm  = (props: ListChildComponentProps) =>{
    const register:XForm = props.data[0];
    const me:IMe = props.data[1];
    const item:IPersonal = props.data[2];
    const firmante:IFirmante = props.data[3];

    const empresa:ICentroTrabajo|undefined = GetEmpresa(item.centrotrabajoId, me);

    return(
        <Document>
            <Page key={1} size="A4" style={PdfStyles.page} >
                {/* <View fixed>
                   <View style={[useStyles.content, {paddingBottom:20, justifyContent:'space-between'}]}>
                        <Text style={{fontSize:8, color:'#8c8c8c', fontWeight:'bold'}} fixed>
                            {`Fecha revisión: ${register.dt_reviewed}`}
                        </Text>
                        <Text style={{fontSize:8}} render={({ pageNumber, totalPages }) => (
                            `Pág. ${pageNumber} / ${totalPages}`
                        )} fixed />
                    </View>
                    <View style={[useStyles.content, {paddingBottom:20}]}>
                        <View style={{width:'25%', marginRight:30, paddingRight:15, borderRight:'1pt solid rgba(0,0,0,0.31)'}}>
                            {empresa?
                                <Image src={empresa.logo} style={useStyles.bussinessData}/>
                            :
                                null
                            }
                            <Text style={{fontWeight:25, fontSize:10, textAlign:'center', marginTop:10}}>{`${empresa?.description}`}</Text>
                        </View>
                        <View style={{width:'70%', height:120}}>
                            <Text style={{fontWeight:50, fontSize:20}}>{`${register.title}`}</Text>
                            <Text style={useStyles.header}>{`Fecha realización: ${GetDatetimeFormat(register.dt_created)}`}</Text>
                            <Text style={useStyles.header}>{`Realizado por: ${register.usuario}`}</Text>
                        </View>
                    </View>
                </View> */}
                <View style={PdfStyles.rheader}> 
                    <View style={PdfStyles.rowlogo}>
                        <View style={PdfStyles.mainlogo}>
                            <Image style={PdfStyles.logo1} src={AppNealia_prevent_icon} />
                        </View>
                        <View style={PdfStyles.mainTitle}>
                            <Text>{`${register.title}`}</Text>                            
                            { empresa ?                                     
                                <Text>{empresa.description}</Text>                                    
                            :   null
                            }
                        </View>
                        <View style={PdfStyles.scdlogo}>
                            <Image style={PdfStyles.logo2} src={empresa!.logo} />
                        </View>
                    </View>                   
                    <View style={PdfStyles.separator}>                        
                    </View>
                    <View style={PdfStyles.info}>
                        <View style={PdfStyles.data}>
                            <View style={PdfStyles.campo}>
                                <Text>FECHA</Text>
                            </View>
                            <View style={PdfStyles.dato}>
                                <Text>{`${GetDatetimeFormat(register.dt_created)}`}</Text>
                            </View>
                        </View>
                        <View style={PdfStyles.data}>
                            <View style={PdfStyles.campo}>
                                <Text>RESPONSABLE</Text>
                            </View>
                            <View style={PdfStyles.dato}>
                                <Text>{`${register.usuario}`}</Text>
                            </View>
                        </View> 
                        <View style={PdfStyles.data}>
                            <View style={PdfStyles.campo}>
                                <Text>PERSONAL</Text>
                            </View>
                            <View style={PdfStyles.dato}>
                                <Text>{`${firmante.name}`}</Text>
                            </View>
                        </View> 
                        <View style={PdfStyles.data}>
                            <View style={PdfStyles.campo}>
                                <Text>DNI</Text>
                            </View>
                            <View style={PdfStyles.dato}>
                                <Text>{`${register.dni}`}</Text>
                            </View>
                        </View>                        
                    </View>                    
                </View>
                <View style={PdfStyles.apartado}>
                    <View style={PdfStyles.titlesec}>
                        <View style={PdfStyles.titletext}><Text><Text style={PdfStyles.numeration}> // </Text>{`Puesto de Trabajo`}</Text></View>
                    </View>
                    <View style={PdfStyles.comentarios}>                        
                        <Text style={[PdfStyles.global, PdfStyles.ctextLegal]}>{`${register.puestoTrabajo.name}`}</Text>
                    </View>
                </View>                
                <View style={PdfStyles.apartado}>
                    <View style={PdfStyles.titlesec}>
                        <View style={PdfStyles.titletext}><Text><Text style={PdfStyles.numeration}> // </Text>{`RELACIÓN DE MAQUINARIA Y EQUIPOS DE TRABAJO`}</Text></View>
                    </View>
                    <View style={PdfStyles.comentarios}>                        
                        <Text style={PdfStyles.ctextLegal}>{`${register.relationMachine}`}</Text>
                    </View>
                </View>
                {/* PDF Footer with page counter*/}
                {/* Footer con texto */}
                {/* <Text style={PdfStyles.footer} render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages} | Código: ${informe.code} - Autor: ${informe.author} Fecha de creación: ${formatDate(informe.dtCreated)} `} fixed /> */}
                <Text style={PdfStyles.footer} render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages} | ${register.title} - Fecha revisión: ${register.dt_reviewed} `} fixed />
                
                {/* Imagen en el footer */}
                <Image style={PdfStyles.footerImage2} src={AppNealia_prevent_icon} fixed />
                <Image style={PdfStyles.footerImage} src={AppNealia_prevent} fixed />
            </Page>
            <Page key={2} size="A4" style={PdfStyles.page} >
            <View style={PdfStyles.apartado}>
                    <View style={PdfStyles.titlesec}>
                        <View style={PdfStyles.titletext}><Text><Text style={PdfStyles.numeration}> // </Text>{`Aclaraciones importantes`}</Text></View>
                    </View>                    
                </View>
                <View style={PdfStyles.comentarios}>
                    <Text style={PdfStyles.ctextLegal}>{txt_legal_01}</Text>
                </View>  

                <View style={PdfStyles.signaturesWrapper}>
                    <View style={PdfStyles.signature}>
                        <Text style={PdfStyles.signatureAutor}>
                            {`Firma responsable:\r\n(${register.supervisor.name})`}
                        </Text>
                        <Image
                            style={PdfStyles.signaturePanel}
                            src={register.supervisor.signature?register.supervisor.signature:''}
                        />
                    </View>
                    <View style={PdfStyles.signature}>
                        <Text style={PdfStyles.signatureAutor}>
                            {`Firma personal:\r\n(${firmante.name})`}
                        </Text>
                        <Image
                            style={PdfStyles.signaturePanel}
                            src={firmante.signature?firmante.signature:''}
                        />
                    </View>
                </View>
                {/* PDF Footer with page counter*/}
                {/* Footer con texto */}
                {/* <Text style={PdfStyles.footer} render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages} | Código: ${informe.code} - Autor: ${informe.author} Fecha de creación: ${formatDate(informe.dtCreated)} `} fixed /> */}
                <Text style={PdfStyles.footer} render={({ pageNumber, totalPages }) => `Página ${pageNumber} de ${totalPages} | ${register.title} - Fecha revisión: ${register.dt_reviewed} `} fixed />
                {/* Imagen en el footer */}
                <Image style={PdfStyles.footerImage2} src={AppNealia_prevent_icon} fixed />
                <Image style={PdfStyles.footerImage} src={AppNealia_prevent} fixed />
            </Page>  
        </Document>
    );
}


const questions = [
    {id:"q_1", question: "Estado", answer: "", comment: "", a_template: [{id:"ADECUADO",name:"ADECUADO"},{id:"NO ADECUADO",name:"NO ADECUADO"},{id:"NO DISPONIBLE",name:"NO DISPONIBLE"}]},
    {id:"q_2", question: "Utilización", answer: "", comment: "", a_template: [{id:"CORRECTA",name:"CORRECTA"},{id:"INCORRECTA",name:"INCORRECTA"},{id:"NO UTILIZADO",name:"NO UTILIZADO"}]},
];

const txt_legal_01 = `En cumplimiento del artículo 19 de la Ley 31/95 de Prevención de Riesgos Laborales referente a la formación de los trabajadores en el que se dicta el deber del empresario de proteger y garantizar que cada trabajador reciba una formación teórica y práctica, suficiente y adecuada, en materia preventiva, tanto en el momento de su contratación, como cuando se produzcan cambios en las funciones que desempeñe o se introduzcan nuevas tecnologías o cambios en los equipos de trabajo.\r\n\r\nHabiendo recibido formación e información sobre el manejo, riesgos y medidas preventivas, y poniendo a su disposición el manual de instrucciones de la citada maquinaria.\r\n\r\n
Dicha maquinaria está homologada y dispone de marcado “CE”, cumpliendo las especificaciones del Real Decreto 1215/97, por el que se establecen las disposiciones mínimas de seguridad y salud para la utilización por los trabajadores de los equipos de trabajo.\r\n\r\n
Y para que surja a los efectos oportunos, se rellenan los siguientes datos y se firma.\r\n\
`;

interface ISignaturePanelProps {
    classes:any;
    handleCloseItemForm: Function;
    handleSign: Function;
    handleDeleteSign: Function;
    handleAskCreateForm: Function;
    register: XForm;
}

interface ISignaturePanelState {
    itemSelected?:IFirmante;
    screen: number;
}

class ISignaturePanel extends Component<ISignaturePanelProps, ISignaturePanelState> {
    constructor(props: ISignaturePanelProps) {
        super(props);
        this.state = {
            screen: 1
        };
    }

    renderScreen = (screen: number, register: XForm, s_firmante?: IFirmante) => {
        switch(screen){
            case 1: // Resumen firmas
                return(
                    <div>
                        <Paper className={this.props.classes.paper}>
                            <Table className={this.props.classes.table} aria-label="simple table">  
                                <TableBody key={-1} style={{width:'100%'}}>
                                    <TableRow className={this.props.classes.row}> 
                                        {register.supervisor.signature?
                                            <TableCell  align="center">
                                                <IconButton onClick={()=>this.setState({itemSelected: register.supervisor, screen: 2})} aria-label="delete user">
                                                    <ViewIcon />
                                                </IconButton>
                                            </TableCell>
                                        :
                                            <TableCell  align="center">
                                                <IconButton onClick={()=>this.props.handleSign(register.supervisor, true)} aria-label="delete user">
                                                    <SignIcon />
                                                </IconButton>
                                            </TableCell>
                                        }
                                        <TableCell  align="left">{`${register.supervisor.name}`}</TableCell>
                                        {register.supervisor.signature?
                                            <TableCell  align="center">
                                                <IconButton onClick={()=>this.props.handleDeleteSign(register.supervisor, true)} aria-label="delete user">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        :
                                            null
                                        }
                                    </TableRow> 
                                </TableBody>
                                {register.firmantes.map((item:IFirmante, i:number)=>{
                                    return(
                                        <TableBody key={i} style={{width:'100%'}}>
                                            <TableRow className={this.props.classes.row}> 
                                                {item.signature?
                                                    <TableCell  align="center">
                                                        <IconButton onClick={()=>this.setState({itemSelected: item, screen: 2})} aria-label="delete user">
                                                            <ViewIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                :
                                                    <TableCell  align="center">
                                                        <IconButton onClick={()=>this.props.handleSign(item, false)} aria-label="delete user">
                                                            <SignIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                }
                                                <TableCell  align="left">{`${item.name}`}</TableCell>
                                                {item.signature?
                                                    <TableCell  align="center">
                                                        <IconButton onClick={()=>this.props.handleDeleteSign(item, false)} aria-label="delete user">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                :
                                                    null
                                                }
                                            </TableRow> 
                                        </TableBody>
                                    );
                                })}
                            </Table>
                        </Paper>
                    </div>
                );
            case 2: // Preview firma
                if(!s_firmante)
                    return;
                return(
                    <div>
                        <div style={{border:'rgb(0, 43, 97) solid 3px', borderRadius:'10px', padding:'10px'}}>
                            {s_firmante.name}
                        </div>
                        <div>
                            <div style={{marginTop: 20}}>
                                <img key={0} src={s_firmante.signature} width={'auto'} height={'100px'} />
                            </div>
                        </div>
                    </div>
                );

        }
    }

    renderButtons = (screen: number, item?:IFirmante) => {
        switch(screen){
            case 1:
                return(
                    <DialogActions style={{marginRight:'auto', display:'flex', marginLeft: 'auto', width: '90%', justifyContent:'space-between'}}>
                        <Button onClick={()=>this.props.handleCloseItemForm()} color="primary">
                            {'Cerrar'}
                        </Button>
                        <Button disabled={!this.verifyForm()} onClick={()=>this.props.handleAskCreateForm()} color="primary">
                            {'Generar informe'}
                        </Button>
                    </DialogActions>
                );
            case 2:
                if(!item)
                    return null;

                return(
                    <DialogActions>
                        <Button style={{marginRight:'auto', marginLeft:'30px'}} onClick={()=>this.setState({screen: 1})} color="primary">
                            {'Atrás'}
                        </Button>
                    </DialogActions>
                );
        }
    }

    verifyForm=()=>{
        const {register} = this.props;

        // Verifica que todos han firmado
        const s_index = findIndex(register.firmantes, function(o){return !o.signature});
        if(s_index > -1)
            return false;
        
        if(!register.supervisor.signature)
            return false;

        return true;
    }

    render() {
        const {itemSelected, screen} = this.state;
        const {register} = this.props;

        return (
            <Dialog fullScreen={false} open={true} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{'Firmas'}</DialogTitle>
                <DialogContent>
                    {this.renderScreen(screen, register, itemSelected)}
                </DialogContent>
                    {this.renderButtons(screen, itemSelected)}
            </Dialog>
        );
    }
}
const SignaturePanel = withStyles(styles)(ISignaturePanel);

interface IFormProps {
    me:IMe;
    classes: any;
    handleCloseItemForm: Function;
    item:IPersonal;
    puestosTrabajo: any;
    trabajadores: any;
}

interface IFormState {
    creationModalOpen:boolean;
    creationSignatureOpen: boolean;
    creationSignaturePanel: boolean;
    modalAction:number;
    modalText:any;
    modalType:number;
    titleModal: string;
    htmlBody:boolean;
    loading:boolean;
    loadingModal:boolean;
    register:XForm;
    m01modalopen:boolean;
    s_firmante?: IFirmante;
    s_firmante_role: boolean;
    bottomreached:boolean;
}

class Form extends Component<IFormProps, IFormState> {
    _going2upload:any = {attachments:[]};
    _form_ref:any = React.createRef();

    constructor(props: IFormProps) {
        super(props);
        this.state = {
            creationModalOpen:false,
            creationSignatureOpen: false,
            creationSignaturePanel: false,
            modalType: -1,
            modalAction: -1,
            modalText:'',
            htmlBody:false,
            loading: false,
            loadingModal: false,
            s_firmante_role: false,
            titleModal: `MENSAJE`,
            register: {
                dt_reviewed: `27/10/16`,
                dt_created: new Date(),
                dt_edited: new Date(),
                title: `Autorización de uso de maquinaria`,
                usuario: props.me.userIdName,
                m01: [],
                m02: [],
                puestoTrabajo: null,
                relationMachine: "",
                dni: "",
                firmantes: [
                    {
                        ...props.item,
                        name: `${props.item.name} ${props.item.surname}`
                    }
                    // {
                    //     id: 1,
                    //     name_surname: props.me.userIdName,
                    // },
                    // {
                    //     id: 2,
                    //     name_surname: `${props.item.name} ${props.item.surname}`
                    // }
                ],
                supervisor: {
                    id: `0000000`,
                    name: props.me.userIdName,
                },
            },
            m01modalopen: false,
            bottomreached: false
        };
    }

    componentDidMount(){
        this.addlisteners();
    }

    CheckBottomReached=()=>{
        return (this._form_ref.current.scrollHeight - this._form_ref.current.scrollTop - this._form_ref.current.clientHeight < 10);
    }

    addlisteners=()=>{
        setTimeout(() => {
            if(this._form_ref.current == null) {
                this.addlisteners();
            } else {
                const bottomReached = this.CheckBottomReached();
                if(bottomReached)
                    this.setState({bottomreached: true});
                this._form_ref.current.addEventListener('scroll', this.isscrolling);
                this._form_ref.current.addEventListener('resize', this.isscrolling);
            }
        }, 150);
    }

    isscrolling=()=>{
        const bottomReached = this.CheckBottomReached();
        this.setState({bottomreached: bottomReached});
    }

    onCloseCreationModal = () => {
        this.setState({ creationModalOpen: false, modalText: undefined, htmlBody:false });
    }

    onCloseFormAddM01 = () => {
        this.setState({ m01modalopen: false });
    }

    onCloseSignatureModal = () => {
        this.setState({ creationSignatureOpen: false });
    }

    onCloseSignaturePanel = () => {
        this.setState({ creationSignaturePanel: false });
    }

    /* CALL BACKEND */

    sendAttachFiles = async () => {
        let response = await OrderService.createImgBlob(this._going2upload, 8);
        this._going2upload = {attachments:[]};
        let p = `Fallo en el proceso, vuelva a intentarlo.`;
        let modaltype = 1;
        let modalaction = -1;

        if(response == 1) {
            p = `Formulario adjuntado correctamente`;
            modaltype = 4;
            modalaction= 1;
        }
        
        this.setState({
            creationModalOpen:true,
            modalText:p,
            modalType: modaltype,
            modalAction: modalaction,
            loadingModal: false
        });
    }

    /* */

    handleSignatureTaken = (signature:string) => {
        const {s_firmante, register, s_firmante_role} = this.state;
        let nregister = cloneDeep(register);

        if(s_firmante_role){
            nregister.supervisor.signature = signature;
        } else {

            if(!s_firmante)
                return;

            const f_idnex = findIndex(nregister.firmantes, function(o) { return o.id == s_firmante.id});

            if(f_idnex == -1)
                return;
            
            nregister.firmantes[f_idnex].signature = signature;
        }

        this.setState({register: nregister, creationSignatureOpen: false});
        //console.log(signature);
    }

    handleSign = (firmante:IFirmante, issupervisor:boolean) => {
        this.setState({s_firmante: firmante, creationSignatureOpen: true, s_firmante_role: issupervisor});
    }
    
    handleDeleteSign = (firmante:IFirmante, issupervisor:boolean) => {
        const {register} = this.state;

        let nregister = cloneDeep(register);

        if(issupervisor) {
            nregister.supervisor.signature = undefined;
        } else {
            const f_idnex = findIndex(nregister.firmantes, function(o) { return o.id == firmante.id});

            if(f_idnex == -1)
                return;
            
            nregister.firmantes[f_idnex].signature = undefined;
        }

        this.setState({register: nregister});
    }

    handleCreateModal = (typeAction: number) => {
        switch(typeAction){
            case 1: // Implica que se ha adjuntado corractamente el formulario
                this.props.handleCloseItemForm(true);
                this.onCloseCreationModal();
                break;
            case 2: // Implica iniciar el proceso de adjuntar el formulario
                this.setState({loadingModal: true},()=>{
                    this.handleCreateForm();
                });
                break;
            default:
                this.onCloseCreationModal();
        }
    }

    handleChangeSelectDevices=(e:any, list:Array<string>)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.puestoTrabajo = list;
        this.setState({ register: nRegister });
    }

    
    handleChangeUser=(id:string, e:any)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister[id] = e.target.value;
        this.setState({ register: nRegister });
    }

    handleChangeFirmantes=(e:any, list:Array<any>)=>{
        let nRegister = cloneDeep(this.state.register);
        nRegister.firmantes = list;
        this.setState({ register: nRegister });
    }

    handleCreateForm=()=>{

        const {item, me} = this.props;
        const {register} = this.state;

        let promises:any = [];

        let t_register = cloneDeep(register);
        t_register.firmantes = [];

        register.firmantes.map((firmante:IFirmante, idx:number)=>{
            let dale = 
                <PdfForm index={idx} data={[register, me, item, firmante]} style={{}} />

            const blob = pdf(dale).toBlob();

            promises.push(new Promise((resolve, reject)=>{
                blob.then(ret=>{

                    let reader = new FileReader();

                    reader.onloadend = async ()=>{
                        this._going2upload.attachments.push({name: `Informe_Autorización_uso_maquinaria_01_personal.pdf`, firmante: firmante, stream: reader.result, doctype: 3, centrotrabajoId: item.centrotrabajoId, tipo: 3, listadoId: 1, id: firmante.id, metadata: register});
                        //this.setState({loading:true }, ()=>this.sendAttachFiles());
                        //this.sendAttachFiles();
                        resolve(1);
                    }
                    reader.readAsDataURL(ret);

                }).catch(err=>{
                    console.log(err)
                    reject(0)
                })
            }));
        })

        

        Promise.all(promises)
            .then(results => {
                //console.log(this._going2upload.attachments);
                this.sendAttachFiles();
            })

        // blob.then(ret=>{

        //     let reader = new FileReader();

        //     reader.onloadend = async ()=>{
        //         this._going2upload.attachments.push({name: `Informe_EPIs_03_personal.pdf`, stream: reader.result, centrotrabajoId: item.centrotrabajoId, tipo: 3, listadoId: 1, id: item.id, metadata: register});
        //         //this.setState({loading:true }, ()=>this.sendAttachFiles());
        //         this.sendAttachFiles();
        //     }
        //     reader.readAsDataURL(ret);

        // }).catch(err=>{
        //     console.log(err)
        // })
    }

    handleAskCreateForm=()=>{
        const {item} = this.props;
        this.setState({
            modalAction: 2,
            //modalText: `¡ATENCIÓN! ¿Quiere finalizar el formulario y adjuntarlo al trabajador ${item.id}?`,
            modalText: `¡ATENCIÓN! ¿Quiere finalizar el formulario y adjuntarlo?`,
            modalType: 2,
            creationModalOpen: true,
        });
    }

    verifyForm=(register:XForm)=>{
        // Verifica que tenga dni
        if(register.dni.length == 0)
            return false;

        // Verifica que tenga alguna relación
        if(register.relationMachine.length == 0)
            return false;
        
        // Verifica que por lo menos hay un firmante
        if(register.firmantes.length == 0)
            return false;
        
        // Verifica que se ha seleccionado el puesto de trabajo
        if(register.puestoTrabajo == null)
            return false;

        if(!this.state.bottomreached)
            return false;

        return true;
    }

    render() {
        const {register, loading, creationSignaturePanel, s_firmante} = this.state;
        const {me, puestosTrabajo, trabajadores} = this.props;

        /*let usersinspected = register.inspections.map((inspection:IIQuestion, index:number)=>{
            if(inspection.used)
                return(this.renderUser(inspection, index, secciones));
        });*/

        return (
            <Dialog style={{padding:'25px'}} fullScreen={false} open={true} aria-labelledby="form-dialog-title" className='dialog-data'>
                <DialogTitle id="form-dialog-title">
                    <div className="App-header">
                        {`${register.title}`}
                    </div>
                </DialogTitle>
                <DialogContent ref={this._form_ref}>
                    <div>
                    <div  style={{width:'100%', marginTop:10, marginBottom:30}}>
                        <List disabled id={"firmantes"} data={register.firmantes} multiple={true} name={'Trabajador'} placeholder={'Firmantes...'} options={trabajadores} handleChangeSelect={(e:any, list:any)=>this.handleChangeFirmantes(e,list)}/>
                    </div>
                    <div  style={{width:'100%', marginBottom:20}}>
                        <List id={"puestoTrabajo"} data={register.puestoTrabajo} multiple={false} name={'Puesto trabajo'} placeholder={'Puesto trabajo'} options={puestosTrabajo} handleChangeSelect={(e:any, list:any)=>this.handleChangeSelectDevices(e,list)}/>
                    </div>
                    <div>
                        <div className="Form-section">
                            {`Información para el trabajador`}
                        </div>
                        <p style={{whiteSpace:'pre-line', textAlign:'justify'}}>
                            {txt_legal_01}
                        </p>
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <TextFieldInput
                            name={`DNI`}
                            id={'dni'}
                            disabled={false}
                            type={"text"}
                            required={false}
                            handleFieldChange={(id:string, evt:any, type:string)=>this.handleChangeUser(id,evt)}
                            style={3}
                            multiline={false}
                            helper={""}
                            fieldsData={register}
                            maxText={9}
                            noError={true}
                            size={"medium"}
                            shrink={true}
                        />
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <TextFieldInput
                            name={`Relación maquinaria y equipos de trabajo`}
                            id={'relationMachine'}
                            disabled={false}
                            type={"text"}
                            required={false}
                            handleFieldChange={(id:string, evt:any, type:string)=>this.handleChangeUser(id,evt)}
                            style={3}
                            rows={5}
                            multiline={true}
                            helper={""}
                            fieldsData={register}
                            maxText={2000}
                            noError={true}
                            size={"medium"}
                            shrink={true}
                        />
                    </div>
                    <div>
                        {this.state.creationModalOpen?
                            <CreateModal
                                onCloseCreationModal={this.onCloseCreationModal}
                                isOpen={this.state.creationModalOpen}
                                inputText={this.state.modalText}
                                typeModal={this.state.modalType}
                                action={this.handleCreateModal}
                                typeAction={this.state.modalAction}
                                loadingModal={this.state.loadingModal}
                                titleModal={this.state.titleModal}
                            />
                        :
                            null
                        }
                        {this.state.creationSignatureOpen?
                            <Signature
                                onClose={this.onCloseSignatureModal}
                                isOpen={this.state.creationSignatureOpen}
                                handleSignatureTaken={this.handleSignatureTaken}
                                firmante={s_firmante?.name}
                            />
                        :
                            null
                        }
                        {creationSignaturePanel?
                            <SignaturePanel
                                handleCloseItemForm={this.onCloseSignaturePanel}
                                handleSign={this.handleSign}
                                handleDeleteSign={this.handleDeleteSign}
                                register={register}
                                handleAskCreateForm={this.handleAskCreateForm}
                            />
                        :
                            null
                        }
                    </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ borderTop: '#ff972b solid 3px'}}>
                    {!loading?
                        <div style={{marginRight:'auto', display:'flex', marginLeft: 'auto', width: '90%', justifyContent:'space-between'}}>
                            <Button onClick={()=>this.props.handleCloseItemForm(false)} className="cancel-dialog">
                                {'Cancelar'}
                            </Button>
                            <Button disabled={!this.verifyForm(register)} onClick={()=>this.setState({creationSignaturePanel: true})} className={`confirm-dialog ${!this.verifyForm(register)?`confirm-dialog-disable`:``}`}>
                                {`Firmar`}
                            </Button>
                        </div>
                    :
                        <Loader loading={true}/>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Form);